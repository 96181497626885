import React from "react";

const LoadingPost = () => {
  return (
    <>
      {[1, 2, 3].map((index) => (
        <>
          <div className="loading-post" key={ "loading-post-" + index}>
            <div className="animate-pulse bg-slate-100 w-full flex flex-col md:border-b md:border-gray-200 md:pb-3 p-4 rounded-lg mb-4 shadow-sm">
              <div className="flex flex-row items-center mb-3">
                <div className="rounded-full w-8 h-8 bg-gray-300"></div>
                <div className="ms-3 bg-gray-300 h-4 w-24 rounded"></div>
                <div className="ms-3 bg-gray-200 h-3 w-16 rounded"></div>
                <div className="ms-3 bg-gray-300 h-3 w-6 rounded"></div>
              </div>
              <div className="bg-gray-300 h-5 w-3/4 rounded mb-2"></div>
              <div className="bg-gray-200 h-3 w-full rounded mb-3"></div>
              <div className="flex items-center space-x-4 text-sm">
                <div className="flex items-center space-x-2">
                  <div className="bg-gray-300 h-4 w-4 rounded"></div>
                  <div className="bg-gray-300 h-3 w-6 rounded"></div>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="bg-gray-300 h-4 w-4 rounded"></div>
                  <div className="bg-gray-300 h-3 w-6 rounded"></div>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="bg-gray-300 h-4 w-4 rounded"></div>
                  <div className="bg-gray-300 h-3 w-10 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default LoadingPost;
