import book_illustration from "../../Assets/book_illu.png";
import bg_illustration from "../../Assets/bg-illu.png";
import AnimatedWordCycle from "./AnimatedWordCycle";

const Card1 = ({ inviteCode, setInviteCode, handleClick }) => {
  return (
    <div className="flex justify-center items-center bg-white w-full flex-row  rounded-t-2xl">
      <div className="w-1/2 flex flex-col px-10 py-4">
        <div className="flex   mb-3 flex-col">
          <div>
            <AnimatedWordCycle />
          </div>
          <div>
            {/* Description */}
            <p className="text-gray-600 mb-2">
              Rent your favorite books, and engage in meaningful conversations
              with the characters that inspire you.
            </p>
          </div>
        </div>
        <div className="flex mb-6 flex-col">
          <div>
            <button
              className="mt-4 bg-customBlue hover:bg-customBlue text-white font-bold py-2 px-4 rounded-md"
              onClick={() => handleClick()}
            >
              Join Now
            </button>
          </div>
        </div>
      </div>
      <div className="w-1/2 p-4 relative">
        {/* Background image using Tailwind and inline styles if needed */}
        <img
          src={bg_illustration}
          alt="Background Illustration"
          className="w-full h-full absolute right-0 top-0 object-cover z-0  "
        />
        <img
          src={book_illustration}
          alt="Book Illustration"
          className="w-3/4 relative object-cover z-10 translate-x-20"
        />
      </div>
    </div>
  );
};

export default Card1;
