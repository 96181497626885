import React from "react";
import cta_svg from "../../Assets/CTA.svg"; // Assuming the button has a SVG
import bg_illustration from "../../Assets/bg-illu.png"; // Background pattern if needed
import Logo1 from "../../Assets/logo-1.png";
import Logo2 from "../../Assets/logo-2.png";

const Card3 = ({ handleClick }) => {
  return (
    <div className="flex flex-col justify-center items-center bg-customBlue w-full py-16 rounded-t-2xl">
      {/* Central Card */}
      <div className="w-5/6 bg-customYellow rounded-2xl flex flex-col justify-center items-center px-10 py-12 shadow-lg">
        <h2 className=" text-xl md:text-3xl  font-bold text-customBlue mb-4">
          Not Yet Part of Readkar?
        </h2>
        <p className="text-sm md:text-lg  text-customBlue mb-6 text-center">
          Sign up and get access to a vast library of books, mangas and comics.
        </p>
        <button
          className="bg-customBlue text-white font-bold   ps-4 rounded-lg flex items-center"
          onClick={handleClick}
        >
          Sign Up Now
          <img src={cta_svg} alt="arrow icon" className="h-10" />
        </button>
      </div>

      {/* Footer Section */}
      <footer className="flex flex-col items-center justify-center w-full mt-8 text-white">
        <div className="mb-4 flex-col "></div>

        <div className="flex items-center space-x-4">
          <a href="https://www.linkedin.com/company/readkar" className=" bg-white rounded-full px-1">
            <i className="fab fa-linkedin text-customBlue"></i>
          </a>
          <a
            href="https://www.instagram.com/readkar_com/"
            className="text-customBlue bg-customYellow rounded-full px-3 py-2 "
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://x.com/readkar235" className="bg-white rounded-full px-1 text-customBlue">
            <i className="fab fa-twitter"></i>
          </a>
        </div>
        <div>
          <p className="text-sm mt-2">
            +91-9049394749 / +91-7987427069 / +91-8999203746
          </p>
        </div>
        <hr className="border-gray-400 my-4 w-3/4" />
        <div className="flex md:flex-row flex-col-reverse justify-center  items-center md:justify-around mt-2 w-full">
          <div>
            <p className="text-xs mt-2">
              Copyright, Anivedam Technologies Private Limited 2024. All rights reserved.
            </p>
          </div>
          <div className="flex space-x-4 mt-2 text-xs">
            <a href="contact_us" className="hover:underline">
              About
            </a>
            <a href="privacy_policy" className="hover:underline">
              Privacy & Policy
            </a>
            <a href="tnc" className="hover:underline">
              Terms & Conditions
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Card3;
