import { ReactComponent as OpenBook } from "../../Assets/icons/open_book.svg";
import book_missing from "../../Assets/icons/missing-book.png";

const BookListComponent2 = ({ books, title, setOpenBook, orderBook }) => {
  console.log("books", books);

  return (
    <>
      <>
        <div className="flex flex-col w-full mt-[30px] pt-[2px] ml-[35px] mb-5">
          <div className=" text-2xl font-semibold flex w-full justify-start">
            {title}
          </div>
          <div className=" flex flex-row overflow-x-auto gap-[24px]">
            {books.length > 0 &&
              books.map((book) => (
                <>
                  <div
                    className="w-[420px] h-[250px] flex flex-row border-e mt-[32px] pe-2 cursor-pointer  "
                    onClick={() => setOpenBook(book)}
                  >
                    {" "}
                    <div className="w-[160px] min-w-[160px] h-full flex  justify-center  ">
                      {book.image_url ? (
                        <>
                          <img
                            src={book.image_url}
                            alt={book.title}
                            className="w-[160px] h-[250px] object-cover rounded-lg"
                          />
                        </>
                      ) : (
                        <>
                          <div className="flex items-center justify-center w-[140px] h-[200px]">
                            <img
                              src={book_missing}
                              alt={book.title}
                              className="w-[40px] object-cover"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="flex flex-col w-[270px] h-full ms-[23px]">
                      <div className="text-2xl font-semibold h-[68px] line-clamp-2 ">
                        {book.title}
                      </div>
                      <div className="text-md text-gray-500 mt-[8px] line-clamp-1 ">
                        By {book.author}
                      </div>
                      <div className="text-sm text-gray-400 mt-[8px] line-clamp-4 h-[82px]">
                        {book.description}
                      </div>
                      <div
                        className="h-[44px] w-[150px]  bg-customSkyBlue hover:bg-customBlue  hover:text-white  rounded-lg py-[10px] ps-[21px] flex mt-2 "
                        onClick={(event) => {
                          event.stopPropagation();
                          orderBook({ book });
                        }}
                      >
                        <div className="">
                          <OpenBook
                            width="24"
                            height="24"
                            fill="currentColor"
                          />
                        </div>
                        <div className="text-md font-semibold ms-[8px] ">
                          {" "}
                          Rent Now
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
        <div className="w-full  flex justify-center mt-10 mx-[35px]">
          <div className=" h-[1px] bg-gray-200 w-full"></div>
        </div>
      </>
    </>
  );
};

export default BookListComponent2;
