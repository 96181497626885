import React from "react";
import { Helmet } from "react-helmet-async";
import TitleBar from "../Common/TitleBar";
import MbTitleBar from "../Common/MbTitleBar";

const CancellationAndRefund = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Readkar</title>
      </Helmet>
      <div className="h-full flex flex-col">
        <div className="hidden md:flex mb-10">
          <TitleBar />
        </div>
        <div className="flex md:hidden h-20">
          <MbTitleBar />
        </div>
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg overflow-auto flex flex-grow flex-col ">
          <h1 className="text-3xl font-bold text-gray-800 text-center mb-6">
            Cancellation and Refund Policy
          </h1>
          <p className="text-gray-600 text-center mb-10">
            At Readkar, we value your experience and strive to provide the best
            service. Please read our cancellation and refund policy carefully.
          </p>

          <div className="space-y-8">
            {/* Section 1 */}
            <div>
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
                Cancellation Policy
              </h2>
              <p className="text-gray-600">
                - You can cancel your subscription anytime through your account
                settings or by contacting our support team. -{" "}
                <strong>
                  Please note that subscriptions cannot be canceled until all
                  rented books are returned.
                </strong>
                - Once canceled, the subscription will not renew at the next
                billing cycle. - Any ongoing rental services will continue until
                the end of the current subscription period.
              </p>
            </div>

            {/* Section 2 */}
            <div>
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
                Refund Policy
              </h2>
              <p className="text-gray-600">
                - Refunds are issued only under special circumstances, such as
                service disruptions or billing errors. - Refund requests can be
                made by contacting our support team at{" "}
                <span className="text-blue-500">support@readkar.com</span>. -
                Please allow 7-10 business days for refund processing after
                approval.
              </p>
            </div>

            {/* Section 3 */}
            <div>
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
                Contact Us
              </h2>
              <p className="text-gray-600">
                If you have any questions or need assistance with cancellations
                or refunds, feel free to reach out to us:
              </p>
              <ul className="mt-4 space-y-2 text-gray-600">
                <li>
                  📞 <strong>Phone:</strong> +91-8956367644 (9:00 AM - 9:00 PM)
                </li>
                <li>
                  📧 <strong>Email:</strong> support@readkar.com
                </li>
                <li>
                  🏢 <strong>Address:</strong> 1404, Tower A, You 57 Society,
                  Phase 3, Hinjewadi, Pune 411057
                </li>
              </ul>
            </div>
          </div>

          <p className="text-center text-gray-500 mt-10">
            ANIVEDAM TECHNOLOGIES PVT LTD
          </p>
        </div>
      </div>
    </>
  );
};

export default CancellationAndRefund;
