import React, { useState } from "react";
import Loading from "../Common/Loading"; // Assuming you have a loading spinner component
import CustomFloatingInput from "../Utilities/CustomFloatingInput";
import RequestBookItem from "./RequestBookItem";
import { handleSearch } from "./RequestBookUtils";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const RequestBookComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [language, setLanguage] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [error, setError] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const navigate = useNavigate();

  const handleReset = () => {
    setTitle("");
    setAuthor("");
    setLanguage("");
    setSearchResult([]);
    setError(null);
  };

  const handleSearchClick = () => {
    handleSearch({
      title,
      author,
      language,
      setSearchResult,
      setIsLoading,
      setError,
    });
  };

  const handleRedirect = (name) => {
    window.location.replace("/search?query=" + encodeURIComponent(name));
    setSuccessModal(false);
  };

  return (
    <div
      className={`h-full bg-gray-50 overflow-y-auto w-full p-4 md:rounded-tl-2xl flex flex-col items-center `}
    >
      <ToastContainer />
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <Loading />
        </div>
      ) : successModal ? (
        <>
          <div className="w-full h-full flex justify-center items-center p-4">
            <div className="bg-white max-w-md w-full rounded-2xl shadow-lg p-6 transition-transform duration-300 ease-in-out transform hover:scale-105">
              <h1 className="text-center mb-6 font-bold text-2xl text-blue-900">
                Book Requested Successfully!
              </h1>
              <div className="flex flex-col items-center">
                <div className=" items-center mb-4">
                  <img
                    src={selectedBook.bd_image_url[0]}
                    alt={selectedBook.bd_name}
                    className="w-36 h-52 rounded-lg shadow-md object-cover"
                  />
                </div>
                <div className="w-full text-center mb-4">
                  <p className="font-bold text-xl text-gray-800">
                    {selectedBook.bd_name}
                  </p>
                  <p className="text-gray-600">by {selectedBook.bd_author}</p>
                </div>

                <div className="flex justify-around w-full mt-4">
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-lg transition duration-300"
                    onClick={() => handleRedirect(selectedBook.bd_name)}
                  >
                    Go To Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <>
            <div
              className={`flex flex-col items-center w-full max-w-lg space-y-4 ${
                searchResult.length > 0 ? "mt-4" : "mt-0"
              }`}
            >
              <div className="w-full flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 md:items-start items-center">
                <CustomFloatingInput
                  type="text"
                  text="Title"
                  id="title"
                  required={true}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full md:w-1/3"
                />
                <CustomFloatingInput
                  type="text"
                  text="Author"
                  id="author"
                  required={true}
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                  className="w-full md:w-1/3"
                />
                <CustomFloatingInput
                  type="text"
                  text="Language"
                  id="language"
                  required={true}
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  className="w-full md:w-1/3"
                />
              </div>
              <div className=" flex-row w-full items-center justify-center flex space-x-2 ">
                <button
                  className="bg-customBlue text-white font-bold py-2 px-4 rounded transition-colors duration-300  "
                  onClick={handleSearchClick}
                >
                  Search
                </button>
                <button
                  className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded transition-colors duration-300 "
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>
          </>
          {error && <p className="text-red-500 text-center mt-4">{error}</p>}

          {searchResult.length > 0 ? (
            <div className="mt-6 flex flex-col w-full space-y-2 justify-center items-center">
              {searchResult.map((book) => (
                <RequestBookItem
                  key={book.id}
                  book={book}
                  toast={toast}
                  setSuccessModal={setSuccessModal}
                  setSelectedBook={setSelectedBook}
                />
              ))}
            </div>
          ) : (
            !isLoading && !error && <p className="text-gray-500 mt-4"></p>
          )}
        </>
      )}
    </div>
  );
};

export default RequestBookComponent;
