import { BookModel } from "../Serializers/SerializerModels";
import { fetchGetData } from "../Utilities/FetchUtils";

export const truncateText = (text, maxLength) => {
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};

export const handleBookmarkClick = (book) => {
  if (book) {
    fetchGetData(`books/add_bookmark?book_id=${book.id}`);
    getBookmarks();
  }
};

export const handleRemoveBookmark = async (book, setBookmarks) => {
  await fetchGetData(`books/remove_bookmark?book_id=${book.id}`);

  getBookmarks();
};

export const getBookmarks = async () => {
  const { response, error } = await fetchGetData("books/get_all_bookmark");
  if (response) {
    const book_details = response.book_details.map(
      (book) => new BookModel(book)
    );
    localStorage.setItem("bookmarks", JSON.stringify(book_details));
  } else {
    localStorage.setItem("bookmarks", JSON.stringify([]));
  }
};

export const getBookmarksFromLocalStorage = (setBookmarks) => {
  const bookmarks = localStorage.getItem("bookmarks");
  if (bookmarks) {
    const parsedBookmarks = JSON.parse(bookmarks);
    setBookmarks(parsedBookmarks);
  } else {
    setBookmarks([]);
  }
};

export const getBook = async ({ book_id, setBooksDetails }) => {
  const { response, error } = await fetchGetData(
    `books/get_by_id?book_id=${book_id}`
  );
  if (response) {
    const book = new BookModel(response);
    setBooksDetails(book);
  }
};
