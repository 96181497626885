import { useState } from "react";
import { ReactComponent as Like } from "../../Assets/icons/like.svg";
import { ReactComponent as Dislike } from "../../Assets/icons/dislike.svg";
import { ReactComponent as CommentLogo } from "../../Assets/icons/comments.svg";
import {
  deletePost,
  dislikePost,
  getTimestamp,
  likePost,
} from "./CommunityUtils";
import CommentSection from "./CommentSection";

const Post = ({ postData, user_id }) => {
  const [postDataCopy, setPostDataCopy] = useState({ ...postData });
  const [showComments, setShowComments] = useState(false);

  const handleShowComments = () => {
    setShowComments(!showComments);
  };

  const handleLike = () => {
    if (!postDataCopy.like.includes(user_id)) {
      const updatedLikes = [...postDataCopy.like, user_id];
      const updatedDislikes = postDataCopy.dislike.filter(
        (id) => id !== user_id
      );
      setPostDataCopy({
        ...postDataCopy,
        like: updatedLikes,
        dislike: updatedDislikes,
      });
      likePost(postDataCopy.id, updatedLikes, updatedDislikes);
    }
  };

  const handleDislike = () => {
    if (!postDataCopy.dislike.includes(user_id)) {
      const updatedDislikes = [...postDataCopy.dislike, user_id];
      const updatedLikes = postDataCopy.like.filter((id) => id !== user_id);
      setPostDataCopy({
        ...postDataCopy,
        like: updatedLikes,
        dislike: updatedDislikes,
      });
      dislikePost(postDataCopy.id, updatedLikes, updatedDislikes);
    }
  };

  const handleDelete = async () => {
    await deletePost(postDataCopy.id);
  };

  return (
    <div className="md:bg-gray-50 bg-slate-100 w-full flex flex-col md:border-b md:border-gray-200 md:pb-3 p-4 rounded-lg mb-4 shadow-sm">
      <div className="flex flex-row items-center mb-3">
        <div className="rounded-full w-8 h-8 bg-customBlue text-white flex justify-center items-center text-sm font-bold">
          {postDataCopy.username?.charAt(0).toUpperCase()}
        </div>
        <div className="ms-3 text-gray-800 font-semibold text-sm">
          {postDataCopy.username.replace("_", " ")}
        </div>
        <div className="ms-3 text-gray-500 text-xs">
          {getTimestamp(postDataCopy.timestamp)}
        </div>
        <div className="ms-3 text-red-600 text-xs cursor-pointer ">
          {user_id === postDataCopy.created_by && (
            <i className="fa fa-trash" onClick={handleDelete}></i>
          )}
        </div>
      </div>
      <div className="text-lg font-semibold text-gray-800 mb-2">
        {postDataCopy.title}
      </div>
      <div
        className="text-sm text-gray-600 mb-3"
        dangerouslySetInnerHTML={{ __html: postDataCopy.description }}
      ></div>
      <div className="flex items-center space-x-4 text-sm">
        <div
          className={`flex items-center ${
            postDataCopy.like.includes(user_id)
              ? "text-green-600"
              : "text-gray-600"
          } cursor-pointer`}
          onClick={handleLike}
        >
           ̰
          <Like className="text-sm" height="18px" width="18px" />
          <span className="ms-1">{postDataCopy.like.length}</span>
        </div>
        <div
          className={`flex items-center ${
            postDataCopy.dislike.includes(user_id)
              ? "text-red-600"
              : "text-gray-600"
          } cursor-pointer`}
          onClick={handleDislike}
        >
          <Dislike className="text-sm" height="18px" width="18px" />
          <span className="ms-1">{postDataCopy.dislike.length}</span>
        </div>
        <div
          className="flex items-center  cursor-pointer text-gray-500"
          onClick={handleShowComments}
        >
          <CommentLogo height="18px" width="18px" />
          <span className="ms-1 font-bold ">
            {postDataCopy.comment_count}{" "}
            {postDataCopy.comment_count === 1 ? "Comment" : "Comments"}
          </span>
        </div>
      </div>
      {showComments && (
        <div className="mt-2">
          <CommentSection postId={postDataCopy.id} user_id={user_id} />
        </div>
      )}
    </div>
  );
};

export default Post;
