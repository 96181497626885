import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Draggable from "react-draggable";
import { getRole } from "../UserAuthentication/AuthUtils";
import Logo1 from "../../Assets/logo-1.png";
import Logo2 from "../../Assets/logo-2.png";

const userRole = getRole();

export const MobileBottomNav = ({ tabs, selectedTab, setSelectedTab }) => {
  // State to manage whether the nav is collapsed or not

  const navigate = useNavigate();
  const handleOnClick = (tabId) => {
    if (tabId !== selectedTab) {
      setSelectedTab(tabId);
      // Collapse the navbar

      navigate(`/${tabId}`);
    }
  };
  return (
    <div className="fixed md:hidden bottom-0 left-0 right-0 h-[64px] mb-[5px] w-full z-40">
      <div className="mx-[42px] h-full flex items-center justify-evenly bg-customBlue rounded-full">
        {tabs
          .filter((tab) => tab.acl.includes(userRole))
          .map((tab, index) => (
            <div
              key={index}
              className={`inline-flex flex-col items-center justify-center w-[52px] h-[48px] ${
                selectedTab === tab.id ? "bg-customOrange" : ""
              } group rounded-full`}
              onClick={() => handleOnClick(tab.id)}
            >
              {tab.icon}
            </div>
          ))}
      </div>
    </div>
  );
};

export const DesktopSidebar = ({ tabs, selectedTab, setSelectedTab }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();

  const handleOnClick = (tabId) => {
    if (tabId !== selectedTab) {
      navigate(`/${tabId}`);
    }
    setSelectedTab(tabId);
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div
      className={`hidden md:flex flex-col top-0 left-0 z-50 h-full transition-width duration-300 ${
        isCollapsed ? "w-[93px]" : "w-[240px]"
      } bg-customBlue`}
      aria-expanded={!isCollapsed}
    >
      {/* Logo and Toggle Button */}
      <div className="flex items-center relative justify-between h-[84px] transition-all duration-300 px-6 pb-6 pt-6 border-b border-customBlue1 overflow-visible">
        <a href="/" className="flex items-center">
          <img
            src={isCollapsed ? Logo1 : Logo2}
            alt="Readkar Logo"
            className={`${
              isCollapsed ? "w-[40px] h-[40px]" : "w-[200px] h-[34px]"
            }`}
          />
        </a>
      </div>

      {/* Sidebar Tabs */}
      <div className="flex flex-col items-center h-full mt-4 ">
        {tabs
          .filter((tab) => tab.acl.includes(userRole))
          .map((tab) => (
            <button
              key={tab.id}
              className={`flex   ps-[24px] py-[12px] w-full h-[48px]  items-center  hover:bg-gray-700 focus:outline-none transition-colors duration-200 ${
                selectedTab === tab.id
                  ? "bg-gray-800  border-e-4 border-customYellow"
                  : ""
              } `}
              onClick={() => handleOnClick(tab.id)}
              aria-pressed={selectedTab === tab.id}
            >
              <div className="h-[24px] w-[24px]">{tab.icon}</div>
              <div className="ms-[23px]">
                {!isCollapsed && (
                  <span className="text-white ">{tab.label}</span>
                )}
              </div>
            </button>
          ))}
      </div>

      <div
        className="  bg-customBlue  w-full h-[48px] mb-2 ps-[24px] flex  cursor-pointer  text-white"
        onClick={toggleSidebar}
      >
        <div className=" border rounded-full w-[30px] h-[30px] flex items-center justify-center">
          <i
            className={`fa ${
              isCollapsed ? "fa-arrow-right" : "fa-arrow-left"
            } fa-sm `}
          />
        </div>
        {!isCollapsed && <span className="ms-2">Collapse</span>}
      </div>
      <div className="border-b border-gray-700"></div>
      {/* Profile Section */}
      <div className="flex   ps-[24px] py-[28px] w-full h-[88px] gap-[23px] items-center 0">
        <div
          className="flex items-center h-[32px] cursor-pointer"
          onClick={() => {
            navigate("/profile");
          }}
        >
          <i className="fa-solid fa-user text-white text-2xl"></i>
          {!isCollapsed && (
            <span className="text-lg ms-[15px] text-white">Profile</span>
          )}
        </div>
      </div>
    </div>
  );
};
const NavBar = ({ tabs, selectedTab, setSelectedTab }) => {
  return (
    <>
      <MobileBottomNav
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <DesktopSidebar
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    </>
  );
};

export default NavBar;
