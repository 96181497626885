import { Route, Routes } from "react-router-dom";
import Login from "./Components/UserAuthentication/Login";
import Signup from "./Components/UserAuthentication/Signup";
import ProtectedRoutes from "./Components/Common/ProtectedRoutes";
import WaitlistForm from "./Components/Waitlist/WaitlistForm";
import React, { useState, useEffect } from "react";
import BookmarkComponent from "./Components/BookMark/BookmarkComponent";
import MyBooksComponent from "./Components/UserBooks/MyBooks";
import UserProfileComponent from "./Components/User/UserProfile";
import HomeComponent from "./Components/Home/HomeComponent";
import NavBar from "./Components/Common/NavBar";
import OrderComponent from "./Components/Order/OrderComponent";
import SsoVerification from "./Components/UserAuthentication/SsoVerification";
import ForgotPassword from "./Components/UserAuthentication/ForgotPassword";
import SubscriptionPlans from "./Components/User/SubscriptionPlans";
import ChatCommponent from "./Components/Chat/ChatComponent";
import {
  getRole,
  verifyLogin,
} from "./Components/UserAuthentication/AuthUtils";
import ConfirmEmail from "./Components/UserAuthentication/ConfirmEmail";
import LandingPageComponent from "./Components/LandingPage/LandingPageComponent.jsx";
import ForgotPasswordVerify from "./Components/UserAuthentication/ForgotPasswordVerify.jsx";
import RequestBookComponent from "./Components/RequestBook/RequestBookComponent.jsx";
import DeliveryComponent from "./Components/Delivery/DeliveryComponent.jsx";
import BlogPageComponent from "./Components/BlogPage/BlogPageComponent.jsx";
import TermsOfUse from "./Components/Legal/TermsOfUse.jsx";
import PrivacyPolicy from "./Components/Legal/PrivacyPolicies.jsx";
import ContactUs from "./Components/Legal/ContactUs.jsx";
import CancellationAndRefund from "./Components/Legal/CancellationAndRefund.jsx";
import DeliveryAndShipping from "./Components/Legal/DeliveryAndShipping.jsx";
import CommunityPage from "./Components/Community/CommunityPage.jsx";

// icons
import { ReactComponent as HomeLogo } from "./Assets/icons/home.svg";
import { ReactComponent as ChatLogo } from "./Assets/icons/chat_bubble.svg";
import { ReactComponent as BookmarkLogo } from "./Assets/icons/bookmark.svg";
import { ReactComponent as BookLogo } from "./Assets/icons/book_3.svg";
import { ReactComponent as Community } from "./Assets/icons/community.svg";
import CreateBlog from "./Components/BlogPage/CreateBlog.jsx";


export const UserContext = React.createContext(null);

function App() {
  const [selectedTab, setSelectedTab] = useState("home");
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    const verifyUserLogin = async () => {
      setIsUserLoggedIn(await verifyLogin());
    };

    verifyUserLogin();
  }, []);
  const userRole = getRole();

  const tabs = [
    {
      id: "home",
      label: "Home",
      icon: <HomeLogo  height={20} width={20}/>,
      path: "/home",
      component: <HomeComponent setSelectedTab={setSelectedTab} />,
      acl: ["ADMIN", "GOD", "BASIC_USER"],
    },
    {
      id: "chat",
      label: "Chat",
      icon: <ChatLogo  height={20} width={20}/>,
      path: "/chat",
      component: <ChatCommponent />,
      acl: ["ADMIN", "GOD", "BASIC_USER"],
    },
    {
      id: "bookmarks",
      label: "Bookmarks",
      icon: <BookmarkLogo height={20} width={20}/>,
      path: "/bookmarks",
      component: <BookmarkComponent setSelectedTab={setSelectedTab} />,
      acl: ["ADMIN", "GOD", "BASIC_USER"],
    },
    {
      id: "books",
      label: "My Books",
      icon: <BookLogo height={20} width={20}/>,
      path: "/books",
      component: <MyBooksComponent setSelectedTab={setSelectedTab} />,
      acl: ["ADMIN", "GOD", "BASIC_USER"],
    },
    {
      id: "community",
      label: "Community",
      icon: <Community height={20} width={20} />,
      path: "/community",
      component: <CommunityPage setSelectedTab={setSelectedTab} />,
      acl: ["ADMIN", "GOD", "BASIC_USER"],
    },
    // {
    //   id: "delivery",
    //   label: "Delivery",
    //   icon: "fa-solid fa-truck",
    //   path: "/delivery",
    //   component: <DeliveryComponent setSelectedTab={setSelectedTab} />,
    //   acl: ["ADMIN", "GOD"],
    // },
  ];

  return (
    <UserContext.Provider value={{ isUserLoggedIn, setIsUserLoggedIn }}>
      <div className="App h-dvh w-dvw flex flex-col">
        <div className=" "></div>
        <div className="flex-grow w-full overflow-y-auto">
          <Routes>
            <Route path="/waitlist" element={<WaitlistForm />} />
            <Route path="/*" element={<LandingPageComponent />} />
            <Route path="/blogs" element={<BlogPageComponent />} />
            <Route path="/blogs/:titleId" element={<BlogPageComponent />} />
            <Route path="/login" element={<Login />} />
            <Route path="/tnc" element={<TermsOfUse />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route
              path="/cancellation_and_refund"
              element={<CancellationAndRefund />}
            />
            <Route
              path="/delivery_and_shipping"
              element={<DeliveryAndShipping />}
            />
            <Route path="/signup" element={<Signup />} />
            <Route path="/contact_us" element={<ContactUs />} />
            {tabs
              .filter((tab) => tab.acl.includes(userRole))
              .map((tab) => (
                <Route
                  key={tab.id}
                  path={tab.path}
                  element={
                    <ProtectedRoutes>
                      <div className="flex flex-col md:flex-row h-dvh w-full">
                        <div className="md:w-50 lg:w-50 md:h-full order-last md:order-none">
                          <NavBar
                            tabs={tabs}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                          />
                        </div>
                        <div className="w-full flex-grow overflow-y-auto ">
                          {tab.component}
                        </div>
                      </div>
                    </ProtectedRoutes>
                  }
                />
              ))}
            <Route
              path="/profile"
              element={
                <ProtectedRoutes>
                  <div className="flex flex-col md:flex-row  h-dvh w-full">
                    <div className="md:w-50 lg:w-50 md:h-full order-last md:order-none">
                      <NavBar
                        tabs={tabs}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                      />
                    </div>
                    <div className="w-full  flex-grow overflow-y-auto ">
                      <UserProfileComponent setSelectedTab={setSelectedTab} />
                    </div>
                  </div>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/order"
              element={
                <ProtectedRoutes>
                  <OrderComponent />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/search"
              element={
                <ProtectedRoutes>
                  <div className="flex flex-col md:flex-row h-dvh w-full">
                    <div className="md:w-50 lg:w-50 md:h-full order-last md:order-none">
                      <NavBar
                        tabs={tabs}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                      />
                    </div>
                    <div className="w-full flex-grow overflow-y-auto ">
                      <HomeComponent setSelectedTab={setSelectedTab} />
                    </div>
                  </div>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/request-book"
              element={
                <ProtectedRoutes>
                  <div className="flex flex-col md:flex-row h-[100%] w-full">
                    <div className="md:w-16 lg:w-20 md:h-full order-last md:order-none">
                      <NavBar
                        tabs={tabs}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                      />
                    </div>
                    <div className="w-full flex-grow overflow-y-auto bg-customBlue">
                      <RequestBookComponent />
                    </div>
                  </div>
                </ProtectedRoutes>
              }
            />
            RequestBookComponent
            <Route path="/sso_access" element={<SsoVerification />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/confirm_email" element={<ConfirmEmail />} />
            <Route path="/reset_password" element={<ForgotPasswordVerify />} />
            <Route path="/create_abc_bloggg" element={<CreateBlog />} />
            <Route
              path="/subscription"
              element={
                <ProtectedRoutes>
                  <SubscriptionPlans />
                </ProtectedRoutes>
              }
            />
          </Routes>
        </div>
      </div>
    </UserContext.Provider>
  );
}

export default App;
