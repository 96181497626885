import React from "react";

const CommentShimmer = () => {
  return (
    <div className="animate-pulse w-full flex flex-col md:pb-3 p-4 rounded-lg mb-2">
      {/* Profile section */}
      <div className="flex flex-row items-center mb-3">
        <div className="rounded-full w-8 h-8 bg-gray-300"></div>
        <div className="ms-3 bg-gray-300 h-4 w-24 rounded"></div>
        <div className="ms-3 bg-gray-200 h-3 w-16 rounded"></div>
        <div className="ms-3 bg-gray-300 h-3 w-6 rounded"></div>
      </div>
      {/* Comment text */}
      <div className="bg-gray-300 h-3 w-3/4 rounded mb-2"></div>
      <div className="bg-gray-300 h-3 w-2/4 rounded mb-2"></div>
      {/* Actions */}
      <div className="flex items-center space-x-4 text-sm">
        <div className="flex items-center space-x-2">
          <div className="bg-gray-300 h-4 w-4 rounded"></div>
          <div className="bg-gray-300 h-3 w-6 rounded"></div>
        </div>
        <div className="flex items-center space-x-2">
          <div className="bg-gray-300 h-4 w-4 rounded"></div>
          <div className="bg-gray-300 h-3 w-6 rounded"></div>
        </div>
      </div>
    </div>
  );
};

const CommentsLoading = ({ count = 3 }) => {
  return (
    <div>
      {Array.from({ length: count }).map((_, index) => (
        <CommentShimmer key={index} />
      ))}
    </div>
  );
};

export default CommentsLoading;
