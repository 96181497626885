import React, { useState } from "react";
import RichTextEditor from "./RichTextEditor";
import { addPost } from "./CommunityUtils";
// import MbAddPost from "./MbAddPost";

const AddPost = ({ setShowAddPost }) => {
  const [postData, setPostData] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePost = async () => {
    setLoading(true);
    await addPost({ title, content: postData })
      .then((res) => {
        setShowAddPost(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    window.location.reload();
  };

  return (
    <>
      <MbAddPost
        setShowAddPost={setShowAddPost}
        postData={postData}
        setPostData={setPostData}
        title={title}
        setTitle={setTitle}
        loading={loading}
        handlePost={handlePost}
      />
      <DkAddPost
        setShowAddPost={setShowAddPost}
        postData={postData}
        setPostData={setPostData}
        title={title}
        setTitle={setTitle}
        loading={loading}
        handlePost={handlePost}
      />
    </>
  );
};

export default AddPost;

const MbAddPost = ({
  setShowAddPost,
  postData,
  setPostData,
  title,
  setTitle,
  loading,
  handlePost,
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 md:hidden">
      <div className="fixed bottom-0 flex-col bg-white z-50 w-full h-10/12 min-h-[10/12] max-h-[11/12] rounded-t-2xl">
        {/* Header */}
        <div className="flex flex-row min-h-[56px] shadow-sm items-center ps-[33px] pe-[18px]">
          <div className="text-xl font-semibold text-customBlue line-clamp-1 w-11/12 flex justify-start">
            Create Post
          </div>
          <div className="w-1/12 flex justify-end">
            <button
              className="text-xl sm:text-2xl font-bold text-gray-600 hover:text-gray-800"
              onClick={() => setShowAddPost(false)}
            >
              &times;
            </button>
          </div>
        </div>
        <div className="w-full h-[1px] bg-gray-200 mb-2"></div>

        {/* Header 1 */}
        <div className="flex flex-row h-[56px] border-b mx-[33px] items-center text-bold">
          <div className="text-lg font-semibold text-customBlue line-clamp-1 w-11/12 flex justify-start">
            Post
          </div>
        </div>

        {/* Title */}
        <div className="flex mx-[33px] mt-6 flex-col">
          <div className="text-base text-gray-600">Enter Title</div>
          <div className="w-full h-[45px] mt-2">
            <input
              type="text"
              className="w-full h-full border border-gray-300 rounded-lg px-3 py-2"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter Title"
            />
          </div>
        </div>

        {/* Rich Text Editor */}
        <div className="w-full mt-6 px-[33px] mb-4">
          <RichTextEditor postData={postData} setPostData={setPostData} />
        </div>
        <div className="w-full px-[33px] mb-2 flex justify-end">
          <button
            className=" h-[45px] w-[150px] text-sm font-semibold bg-customBlue text-white rounded-lg flex justify-center items-center"
            onClick={() => handlePost()}
            disabled={loading}
          >
            Post
          </button>
        </div>
      </div>
    </div>
  );
};

const DkAddPost = ({
  setShowAddPost,
  postData,
  setPostData,
  title,
  setTitle,
  loading,
  handlePost,
}) => {
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 md:flex justify-center items-center z-50 hidden ">
        <div className="fixed  inset-y-0 right-0 bg-white shadow-xl w-[568px] h-full rounded-l-lg ">
          <div className="h-full flex flex-col w-full">
            {/* Header */}
            <div className="flex flex-row w-full h-84 px-[33px] items-center py-[27px]  shadow-md">
              <div className="text-2xl font-semibold text-customBlue line-clamp-1 w-11/12  flex justify-start ">
                Create Post
              </div>
              <div className="w-1/12 flex justify-end"></div>
              <div className="w-1/12 flex justify-end">
                <button
                  className="text-xl sm:text-2xl font-bold text-gray-600 hover:text-gray-800"
                  onClick={() => setShowAddPost(false)}
                >
                  &times;
                </button>
              </div>
            </div>
            {/* Header 1 */}
            <div className="flex flex-row h-[56px] border-b mx-[33px] items-center text-bold">
              <div className="text-lg font-semibold text-customBlue line-clamp-1 w-11/12 flex justify-start">
                Post
              </div>
            </div>

            {/* Title */}
            <div className="flex mx-[33px] mt-6 flex-col">
              <div className="text-base text-gray-600">Enter Title</div>
              <div className="w-full h-[45px] mt-2">
                <input
                  type="text"
                  className="w-full h-full border border-gray-300 rounded-lg px-3 py-2"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>

            {/* Rich Text Editor */}
            <div className="w-full mt-6 px-[33px] mb-4">
              <RichTextEditor postData={postData} setPostData={setPostData} />
            </div>
            <div className="w-full px-[33px] mb-2 flex justify-end">
              <button
                className=" h-[45px] w-[150px] text-sm font-semibold bg-customBlue text-white rounded-lg flex justify-center items-center"
                onClick={() => handlePost()}
                disabled={loading}
              >
                Post
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
