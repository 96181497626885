import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

const RichTextEditor = ({ postData, setPostData }) => {
  const editorRef = useRef(null);
  const quillRef = useRef(null);

  useEffect(() => {
    if (!quillRef.current && editorRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, false] }],
            [{ align: [] }],
          ],
        },
      });

      if (postData) {
        quillRef.current.clipboard.dangerouslyPasteHTML(postData);
      }

      quillRef.current.on("text-change", () => {
        setPostData(quillRef.current.root.innerHTML);
      });
    }
  }, [postData, setPostData]); // Ensure this doesn't initialize more than once

  return (
    <div
      className="w-full border p-1"
      ref={editorRef}
      style={{
        height: "200px",
      }}
    ></div>
  );
};

export default RichTextEditor;
