import React, { useState } from "react";
import RichTextEditor from "../Community/RichTextEditor";
import { fetchPostData } from "../Utilities/FetchUtils";
import { BlogRequest } from "../Serializers/SerializerModels";
import { getAccessToken } from "../UserAuthentication/AuthUtils";
const apiUrl = process.env.REACT_APP_API_URL;

const CreateBlog = () => {
  const [formData, setFormData] = useState({
    bd_title: "",
    bd_description: "",
    bd_author: "",
    files: [],
  });

  const setDescription = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      bd_description: value,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files); // Convert FileList to Array
    console.log("Selected files:", selectedFiles); // Debugging
    setFormData({ ...formData, files: selectedFiles }); // Update state
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = getAccessToken();
    const data = new FormData();
    data.append("bd_title", formData.bd_title);
    data.append("bd_description", formData.bd_description);
    data.append("bd_author", formData.bd_author);
    console.log(formData.files)
    for (let i = 0; i < formData.files.length; i++) {
        data.append("files", formData.files[i]); // Notice 'files[]' key
      }
    try {
        const response = await fetch(`${apiUrl}/blog/create`, {
          method: "POST",
          body: data,
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        });
  
        if (response.ok) {
          alert("Blog created successfully!");
          setFormData({ bd_title: "", bd_description: "", bd_author: "", files: [] });
        } else {
          alert("Failed to create blog");
        }
      } catch (error) {
        console.error("Error creating blog:", error);
        alert("An error occurred");
      }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-lg bg-white rounded-lg shadow-md p-8">
        <h1 className="text-2xl font-bold text-center mb-6 text-gray-700">Create Blog</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="bd_title"
              className="block text-gray-600 font-medium mb-2"
            >
              Title:
            </label>
            <input
              type="text"
              id="bd_title"
              name="bd_title"
              value={formData.bd_title}
              onChange={handleChange}
              placeholder="Enter blog title"
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-400 outline-none"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="bd_description"
              className="block text-gray-600 font-medium mb-2"
            >
              Description:
            </label>
           <RichTextEditor postData={formData.bd_description} setPostData={setDescription} />
          </div>
          <div className="mb-4">
            <label
              htmlFor="bd_author"
              className="block text-gray-600 font-medium mb-2"
            >
              Author:
            </label>
            <input
              type="text"
              id="bd_author"
              name="bd_author"
              value={formData.bd_author}
              onChange={handleChange}
              placeholder="Enter author name"
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-400 outline-none"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="files"
              className="block text-gray-600 font-medium mb-2"
            >
              Files:
            </label>
            <input
              type="file"
              id="files"
              name="files"
              multiple
              onChange={(e) => handleFileChange(e)}
              className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:rounded-full file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-600 hover:file:bg-indigo-100"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white font-semibold py-2 px-4 rounded-lg hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-300"
          >
            Create Blog
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateBlog;
