import { useState } from "react";
import { ReactComponent as OpenBook } from "../../Assets/icons/open_book.svg";
import book_missing from "../../Assets/icons/missing-book.png";

const MbBookListsComponent = ({ title, books, setOpenBook, orderBook }) => {
  const [viewMore, setViewMore] = useState(false);
  return (
    <>
      <>
        <div className="flex flex-col w-full mt-[30px] pt-[2px]  px-[24px] ">
          <div className=" text-xl font-semibold flex w-full justify-start">
            {title}
          </div>
          <div className=" grid grid-cols-2  gap-[20px] mt-[19px]  transition-all duration-300 ">
            {books.length > 0 &&
              books.slice(0, viewMore ? books.length : 4).map((book) => (
                <>
                  <div
                    className="flex flex-col p-[10px] pb-[16px] rounded-lg bg-[#F7F9FB]"
                    onClick={() => setOpenBook(book)}
                  >
                    <div className="w-full min-h-[250px] ">
                      {book.image_url ? (
                        <>
                          <img
                            src={book.image_url}
                            alt={book.title}
                            className="w-full h-full object-cover rounded-lg"
                          />
                        </>
                      ) : (
                        <>
                          <div className="w-full h-full flex justify-center items-center">
                            <img
                              src={book_missing}
                              alt={book.title}
                              className="w-[40px] h-[40px] object-cover rounded-lg"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      <div className="text-base font-semibold h-[47px] line-clamp-2 mt-[11px]">
                        {book.title}
                      </div>
                      <div className="text-xs text-gray-500 mt-[8px] h-[16px] line-clamp-1">
                        By {book.author}
                      </div>

                      <div className="mt-[16px]">
                        <div
                          className="h-[32px] w-[98px]  bg-customSkyBlue hover:bg-customBlue  hover:text-white  rounded-lg flex items-center justify-center  mt-[11px] "
                          onClick={(event) => {
                            event.stopPropagation();
                            orderBook({ book });
                          }}
                        >
                          <div className="">
                            <OpenBook
                              width="14"
                              height="14"
                              fill="currentColor"
                            />
                          </div>
                          <div className="text-xs font-semibold ms-[8px] ">
                            {" "}
                            Rent Now
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
          <div className="w-full flex justify-start items-center mt-10 ">
            <div
              className="text-customBlue font-semibold cursor-pointer text-sm"
              onClick={() => setViewMore(!viewMore)}
            >
              {viewMore ? "View Less" : " View More"}
              <i
                className={`fa-solid text-sm fa-chevron-${
                  viewMore ? "up" : "down"
                } ms-1`}
              ></i>
            </div>
          </div>
        </div>

        <div className="w-full  flex justify-center mt-10">
          <div className="w-3/4 h-[1px] bg-gray-200"></div>
        </div>
      </>
    </>
  );
};

export default MbBookListsComponent;
