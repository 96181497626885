import sad_dog_illustration from "../../Assets/sad-dog-2.png";

const ErrorNoValidSubscriptionFound = ({ onClose }) => {
  return (
    <div className="flex justify-center items-center h-full">
      <div className="relative flex justify-center items-center h-full">
        <div className="max-w-lg w-full p-6 transition duration-300 ease-in-out">
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4  text-gray-600 hover:text-gray-800 cursor-pointer font-bold py-1 px-2 rounded"
            aria-label="Close"
          >
            <i className="fas fa-times text-2xl"></i>
          </button>
          <div className="flex flex-col items-center">
            <img
              src={sad_dog_illustration}
              alt="Sad Dog Illustration"
              className="w-1/2"
            />
            <h1 className="text-start mb-4 font-semibold text-2xl text-customBlue">
              Oops!!!
            </h1>
            <div className="text-center mb-4">
              You don't have any active subscription.
            </div>

            <div className="flex justify-center w-full">
              <a
                href="/profile?tab=subscription"
                className="mt-4 bg-customBlue text-white font-bold py-2 px-4 rounded"
              >
                Subscribe Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorNoValidSubscriptionFound;
