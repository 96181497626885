import { ChatMessageRequest, ChatsDetailModel } from "../Serializers/SerializerModels";
import { fetchGetData, fetchPostData } from "../Utilities/FetchUtils";

export const getMessages = async (chat_session_id, setMessages) => {

    const { response, error } = await fetchGetData(`chat/get_conversation?chat_session_id=${chat_session_id.replace(' ', '-')}`);
    if (response) {
        response.shift()
        setMessages(response);
    } else if (error) {
        console.error("Error fetching messages:", error);
    }
};

export const getChats = async (setChats, setIsLoading) => {
    setIsLoading(true);
    const { response, error } = await fetchGetData(`chat/get_chats`);
    if (response) {

        const chats = response.map((chat) => {
            return new ChatsDetailModel(chat)
        });
        setChats(chats)
    } else if (error) {
        console.error("Error fetching messages:", error);
    }

    setIsLoading(false);
};


export const handleSendMessage = async (inputMessage, setInputMessage, setMessages, selectedShow, selectedCharacter, setIsMessagesLoading) => {
    if (inputMessage.trim() === "") return;

    setIsMessagesLoading(true);
    const newMessage = { role: "user", content: inputMessage };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInputMessage("");

    const chatMessageRequest = new ChatMessageRequest({
        message: inputMessage,
        chat_session_id: (selectedShow.replace(/ /g, '-') + '_' + selectedCharacter.replace(/ /g, '-')).replace(/ /g, '-'),
        character: selectedCharacter,
        source: selectedShow
    });

    const { response, error } = await fetchPostData('chat/message', chatMessageRequest);

    if (response) {
        const assistantMessage = { role: "assistant", content: response };
        setMessages((prevMessages) => [...prevMessages, assistantMessage]);
    } else if (error) {
        const err = await error.json();
        setMessages((prevMessages) => [...prevMessages, { role: "assistant", content: err.detail }]);
    }

    setIsMessagesLoading(false);
};


export const handleDeleteChat = async (chat, setIsLoading) => {
    setIsLoading(true);
    const { response, error } = await fetchGetData(`chat/delete_chat?chat_session_id=${(chat.source + '_' + chat.character).replace(' ', '-')}`);
    if (response) {
        console.log("Chat deleted successfully");
    } else if (error) {
        console.error("Error deleting chat:", error);
    }

    setIsLoading(false);
}