import Logo1 from "../../Assets/logo-1.png";
import { ReactComponent as Sparkle } from "../../Assets/icons/sparkle_icon.svg";
import { ReactComponent as PaperPlaneRight } from "../../Assets/icons/PaperPlaneRight.svg";
import { getChats, handleDeleteChat } from "./ChatUtils";
import { useState } from "react";
import { ReactComponent as Delete } from "../../Assets/icons/delete.svg";

const DkChatComponent = ({
  chats,
  characterInput,
  showInput,
  setCharacterInput,
  setShowInput,
  handleNewChat,
  handleCharacterSelect,
  setChats,
  setIsLoading,
  isCharacterSelected,
  inputMessage,
  setInputMessage,
  handleSend,
  handleKeyPress,
  messages,
  isMessagesLoading,
  setIsCharacterSelected,
  selectedCharacter,
  selectedShow,
}) => {
  return (
    <>
      <div className="w-full h-full flex flex-col">
        {!isCharacterSelected ? (
          <>
            <DkChatSelect
              chats={chats}
              characterInput={characterInput}
              showInput={showInput}
              setCharacterInput={setCharacterInput}
              setShowInput={setShowInput}
              handleNewChat={handleNewChat}
              handleCharacterSelect={handleCharacterSelect}
              setChats={setChats}
              setIsLoading={setIsLoading}
            />
          </>
        ) : (
          <>
            <DkChatBox
              messages={messages}
              chats={chats}
              selectedCharacter={selectedCharacter}
              selectedShow={selectedShow}
              setInputMessage={setInputMessage}
              inputMessage={inputMessage}
              handleSend={handleSend}
              isCharacterSelected={isCharacterSelected}
              handleKeyPress={handleKeyPress}
              isLoading={setIsLoading}
              isMessagesLoading={isMessagesLoading}
              setIsCharacterSelected={setIsCharacterSelected}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DkChatComponent;

const DkChatSelect = ({
  chats,
  setCharacterInput,
  characterInput,
  setShowInput,
  showInput,
  handleNewChat,
  handleCharacterSelect,
  setChats,
  setIsLoading,
}) => {
  const [enterCharacter, setEnterCharacter] = useState(false);
  const cardData = [
    {
      title: "Explore Possibilities",
      line1: "Curious about alternate endings or 'what-ifs'?",
      line2: "Chat with characters and uncover insights beyond the plot!",
      logo: Sparkle,
    },
    {
      title: "Unravel Mysteries",
      line1: "The plot thickens, and secrets abound.",
      line2: "Converse with characters to piece it all together!",
      logo: Sparkle,
    },
    {
      title: "Challenge Their Choices",
      line1: "Why did they act the way they did?",
      line2:
        "Ask questions, challenge their reasoning, and explore their minds.",
      logo: Sparkle,
    },
  ];

  return (
    <div className="w-full h-full overflow-y-auto">
      <div className="w-full  text-2xl font-semibold text-customBlue h-[84px] flex items-center ps-[35px] pt-[22px] pb-[33px] border-b">
        Chat
      </div>

      {!enterCharacter && (
        <>
          <div className=" w-full flex justify-center pt-[24px]">
            <img src={Logo1} alt="Logo" className="w-[139px] h-[150px]" />
          </div>
          <>
            <div className=" w-full flex justify-center flex-row mt-[75px] mt- gap-10">
              {cardData.slice(0, 3).map((card) => (
                <>
                  <div className=" mx-[23px] flex flex-col  rounded-2xl w-[280px] h-[208px] ">
                    <div className="w-full flex justify-center mb-[8px]">
                      <card.logo width="32px" height="32px" />
                    </div>
                    <div className="w-full flex justify-center text-lg font-bold  ">
                      {card.title}
                    </div>
                    <div className="w-full flex text-center justify-center text-lg bg-[#F7F9FB] rounded-2xl mt-4  ">
                      {card.line1}
                    </div>
                    <div className="w-full flex text-center justify-center text-lg bg-[#F7F9FB] rounded-2xl mt-4  ">
                      {card.line2}
                    </div>
                  </div>
                </>
              ))}
            </div>{" "}
          </>
        </>
      )}

      <></>
      {!enterCharacter ? (
        <div className="w-full flex justify-center mt-[75px] ">
          <div
            className="  text-white font-semibold flex justify-center items-center h-11 bg-customBlue rounded-xl w-[110px] cursor-pointer"
            onClick={() => setEnterCharacter(true)}
          >
            Start Chat
          </div>
        </div>
      ) : (
        <>
          <div className="w-full flex justify-center mt-[24px] ">
            <div className="flex flex-row items-center mt-[16px]  h-[178px] w-[920px] bg-[#F7F9FB] rounded-xl justify-center px-[169px] mx-[120px]">
              <div className="flex flex-col me-[30px]">
                <div className="text-sm h-[22px]">
                  Book Name <span className="text-red-600">*</span>
                </div>
                <div className=" h-[44px] bg-[#F7F9FB] rounded-xl mt-4 flex justify-center items-center border">
                  <input
                    type="text"
                    placeholder="Enter Book Name"
                    className=" rounded-xl p-2 "
                    onChange={(e) => setShowInput(e.target.value)}
                    value={showInput}
                  />
                </div>
              </div>
              <div className="flex flex-col me-[30px]">
                <div className="text-sm h-[22px] ">
                  Character Name <span className="text-red-600">*</span>
                </div>
                <div className="h-[44px] bg-[#F7F9FB] rounded-xl mt-4 flex justify-center items-center border">
                  <input
                    type="text"
                    placeholder="Enter Character Name"
                    className=" rounded-xl p-2 "
                    value={characterInput}
                    onChange={(e) => setCharacterInput(e.target.value)}
                  />
                </div>
              </div>

              <div
                className={`text-white font-semibold flex justify-center items-center h-[40px] w-[40px] mt-[30px]  rounded-xl  ${
                  showInput === "" || characterInput === ""
                    ? "bg-gray-400 text-white"
                    : `bg-customBlue text-white cursor-pointer`
                }`}
                onClick={() => handleNewChat()}
              >
                <PaperPlaneRight width="20px" height="20px" />
              </div>
            </div>
          </div>

          {/* OLD CHATS */}
          {chats.length > 0 && (
            <>
              <div className="w-full px-[100px] text-lg font-semibold mt-[23px] mb-[18px]  ">
                Recent Chats
              </div>
              <div className="w-full flex flex-col overflow-y-auto gap-[16px]  px-[100px]">
                {chats.map((chat) => (
                  <>
                    <div
                      className="flex flex-row  min-w-[920px] min-h-[93px]  items-center justify-center border rounded-2xl cursor-pointer "
                      onClick={() => {
                        handleCharacterSelect(chat.source, chat.character);
                      }}
                    >
                      <div className="flex flex-row w-1/2 justify-start ps-5 items-center">
                        <div className=" w-[60px] h-[60px] bg-customBlue rounded-full flex justify-center items-center text-white text-lg ">
                          {chat.character[0].toUpperCase()}
                        </div>
                        <div className="text-center text-xl font-semibold  ms-[18px] ">
                          {chat.character.replace(/-/g, " ").toUpperCase()} :{" "}
                          {chat.source.replace(/-/g, " ").toUpperCase()}
                        </div>
                      </div>
                      <div className="flex justify-end w-1/2">
                        <div className="flex flex-row px-4 mt-[12px]">
                          <div
                            className="w-[45px] h-[30px] bg-[#FFE5E5] ms-[12px] rounded-lg flex justify-center items-center text-red-600 text-base"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteChat(chat, setIsLoading);
                              getChats(setChats, setIsLoading);
                            }}
                          >
                            <Delete width="15px" height="15px" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const DkChatBox = ({
  messages,
  selectedCharacter,
  setInputMessage,
  inputMessage,
  handleSend,
  handleKeyPress,
  isMessagesLoading,
  setIsCharacterSelected,
}) => {
  return (
    <>
      <div className="flex flex-col h-full">
        <div className=" h-[52px] mt-[39px] px-[22px] flex flex-row justify-center ">
          <div className="w-full flex flex-row">
            <div className="w-1/12 flex justify-center items-center">
              {" "}
              <i
                className="fa fa-arrow-left mr-4 fa-lg cursor-pointer"
                onClick={() => setIsCharacterSelected(false)}
              />
            </div>
            <div className="w-full  flex-row flex justify-start">
              <div className="flex -space-x-5">
                <div className="w-[47px] h-[47px] justify-center items-center flex border-2 rounded-full bg-white text-slate-800">
                  <i className="fa-solid fa-user text-2xl"></i>
                </div>
                <div className="w-[47px] h-[47px] justify-center items-center flex border-2 rounded-full bg-customBlue text-2xl text-white">
                  {selectedCharacter[0]}
                </div>
              </div>
              <div className="flex h-full item-center justify-center  text-lg font-semibold p-2">
                {selectedCharacter}
              </div>
            </div>
          </div>
        </div>
        {/* Message Box */}
        <div className="flex max-h-[calc(100dvh-200px)] flex-grow  flex-col  overflow-y-auto px-[22px]">
          {messages.map((message, index) => (
            <>
              <div
                className={`w-full mb-[35px] flex ${
                  message.role === "user" ? "flex-row-reverse" : "flex-row"
                } `}
              >
                <div className="w-[47px] h-[47px] justify-center items-center flex border-2 rounded-full bg-white text-slate-800">
                  {message.role === "user" ? (
                    <i className="fa-solid fa-user text-2xl"></i>
                  ) : (
                    <div className="w-[47px] h-[47px] justify-center items-center flex border-2 rounded-full bg-customBlue text-2xl text-white">
                      {selectedCharacter[0]}
                    </div>
                  )}
                </div>
                <div
                  className={` mx-[12px] p-4 max-w-[60%] ${
                    message.role === "user" ? "" : "bg-[#CECECD2E]"
                  } rounded-md ${
                    message.role === "user" ? " justify-end" : " justify-start "
                  } border`}
                >
                  <div>{message.content}</div>
                </div>
              </div>
            </>
          ))}
          {isMessagesLoading && (
            <div className="p-4 shadow-lg my-2 max-w-[60%] flex flex-row gap-2 rounded-lg  bg-gray-300 text-black self-start ">
              <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"></div>
              <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-200"></div>
              <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-400"></div>
            </div>
          )}
        </div>

        {/* Input Box */}
        <div className="w-full  px-[22px] h-[56px]  ">
          <div className="h-full  justify-center items-center flex bg-[#F7F9FB] rounded-xl ps-[20px]">
            <input
              className="text-lg flex-grow bg-[#F7F9FB]  focus:outline-none h-full "
              type="text"
              placeholder="Type a message..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              className="p-4 text-gray-500 hover:text-gray-700"
              onClick={handleSend}
              aria-label="Handle send"
            >
              <PaperPlaneRight width="20px" height="20px" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
