import DidYouKnow from "../Extras/DidYouKnowComponent";
import { ReactComponent as OpenBook } from "../../Assets/icons/open_book.svg";
import book_missing from "../../Assets/icons/missing-book.png";

const DkBookmarkComponent = ({ bookmarks, orderBook, setOpenBook }) => {
  return (
    <>
      {/* Title */}
      <div className="w-full  text-2xl font-semibold text-customBlue h-[84px] flex items-center ps-[35px] pt-[22px] pb-[33px] border-b">
        Bookmark
      </div>
      <div className="w-full mt-6 px-9">
        <DidYouKnow />
      </div>
      <div className="w-full mt-6 grid grid-cols-2 lg:grid-cols-3 gap-9 px-[34px]">
        {bookmarks.map((book) => (
          <>
            <div
              className="border-e px-3 py-[16px] flex flex-row  border-gray-100 cursor-pointer"
              onClick={() => setOpenBook(book)}
            >
              <div className="w-[108px] h-[165px]">
                {!book.image_url ? (
                  <div className="w-[108px] h-[165px] flex items-center justify-center">
                    <img
                      src={book_missing}
                      alt={book.title}
                      className=" object-cover rounded-lg"
                    />
                  </div>
                ) : (
                  <>
                    <img
                      src={book.image_url}
                      alt={book.title}
                      className="w-[108px] h-[165px] object-cover rounded-lg"
                    />
                  </>
                )}
              </div>
              <div className=" flex flex-col ms-3 w-2/3">
                <div className=" flex flex-col h-[99px] ">
                  <div className="text-[22px] font-semibold line-clamp-2 ">
                    {book.title}
                  </div>
                  <div className="text-sm text-gray-500  line-clamp-1">
                    By {book.author}
                  </div>
                </div>
                <div className=" w-full justify-start flex items-end flex-grow ">
                  <button
                    onClick={orderBook}
                    className="  flex flex-row items-center w-[113px]   bg-customSkyBlue hover:bg-customBlue justify-center text-customBlue hover:text-white h-11 rounded-lg"
                  >
                    <OpenBook width="19" height="19" fill="currentColor" />
                    <span className="ml-1 text-sm font-semibold">Rent Now</span>
                  </button>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default DkBookmarkComponent;
