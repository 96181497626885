import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import WaitlistModal from "../Waitlist/WaitlistModal";
import Card3 from "../LandingPage/Card3";
import { useParams } from "react-router-dom";
import BlogCards from "./BlogCards";
import BlogDetails from "./BlogDetails";
import { fetchBlogByTitleId, fetchBlogs } from "./BlogsUtils";
import Loading from "../Common/Loading";
import TitleBar from "../Common/TitleBar";

const BlogPageComponent = () => {
  const [showWaitListModal, setShowWaitListModal] = useState(false);

  const { titleId } = useParams();
  const [blogData, setBlogData] = useState([]);
  const [blogPageData, setBlogPageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const limit = titleId ? 3 : 10;

  useEffect(() => {
    fetchBlogs(setBlogData, setLoading, limit);
  }, []);
  useEffect(() => {
    console.log(titleId);
    if (!titleId) {
      return;
    }
    fetchBlogByTitleId(titleId, setBlogPageData);
  }, [titleId]);

  return (
    <>
      <TitleBar />
      <div className="flex flex-col bg-customYellow">
        <Helmet>
          <title>
            {titleId ? String(blogPageData?.title) : "Readkar | Blogs"}
          </title>
          <meta
            name="description"
            content="Dive into Readkar Blogs - your ultimate destination for book
                     recommendations, literary insights, reading tips, and the 
                     latest book releases. Discover stories, ideas, and inspiration 
                     crafted for every reader."
          />
        </Helmet>
        <div className="rounded bg-white rounded-t-2xl">
          {loading ? (
            <div className="h-screen ">
              {" "}
              <Loading />
            </div>
          ) : (
            <>
              <div className=" mx-auto py-8 ">
                <WaitlistModal
                  show={showWaitListModal}
                  setShowWaitListModal={setShowWaitListModal}
                />
                {titleId ? (
                  <BlogDetails
                    titleId={titleId}
                    blogCardData={blogData}
                    blogData={blogPageData}
                  />
                ) : (
                  <BlogCards blogData={blogData} />
                )}
                <Card3 setShowWaitListModal={setShowWaitListModal} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogPageComponent;
