import { useEffect, useState } from "react";
import {
  dislikeComment,
  getComments,
  getTimestamp,
  likeComment,
  addComment,
  deleteComment,
} from "./CommunityUtils";

import { ReactComponent as Like } from "../../Assets/icons/like.svg";
import { ReactComponent as Dislike } from "../../Assets/icons/dislike.svg";
import CommentsLoading from "./LoadingComment";

const CommentSection = ({ postId, user_id }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [offset, setOffset] = useState(0);
  const [isCommentLoading, setIsCommentLoading] = useState(true);

  useEffect(() => {
    getComments({
      postId,
      setComments,
      offset,
      setOffset,
      setIsCommentLoading,
    });
  }, [postId]);

  const handleCommentLike = async ({ id }) => {
    try {
      await likeComment(id); // Call the like API
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === id
            ? {
                ...comment,
                dislike: comment.dislike.filter((uid) => uid !== user_id),
                like: [...comment.like, user_id], // Like
              }
            : comment
        )
      );
    } catch (error) {
      console.error("Error liking comment:", error);
    }
  };

  const handleCommentDislike = async ({ id }) => {
    try {
      await dislikeComment(id); // Call the dislike API
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === id
            ? {
                ...comment,
                like: comment.like.filter((uid) => uid !== user_id),
                dislike: [...comment.dislike, user_id], // Dislike
              }
            : comment
        )
      );
    } catch (error) {
      console.error("Error disliking comment:", error);
    }
  };

  const handleAddComment = async ({ newComment, postId }) => {
    if (!newComment.trim()) return; // Prevent empty comments

    try {
      const addedComment = await addComment({
        postId,
        comment: newComment,
      });
      setComments((prevComments) => [addedComment, ...prevComments]); // Add new comment to the top
      setNewComment(""); // Clear the input
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };
  const handleDeleteComment = async (id) => {
    try {
      // Add your API call for deleting the comment
      await deleteComment(id);
      setComments((prevComments) =>
        prevComments.filter((comment) => comment.id !== id)
      );
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  return (
    <div className="flex flex-col md:bg-white bg-slate-100 rounded-sm">
      {/* Add Comment Section */}

      <>
        {isCommentLoading ? (
          <CommentsLoading count={1} />
        ) : (
          <>
            <div className="flex items-center p-4 mb-4">
              <input
                type="text"
                className="flex-grow border border-gray-300 rounded-lg p-2 text-sm"
                placeholder="Add a comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
              <button
                className="ms-2 bg-customSkyBlue hover:bg-customBlue text-customBlue hover:text-white text-sm px-4 py-2 rounded-lg "
                onClick={() => handleAddComment({ newComment, postId })}
              >
                Comment
              </button>
            </div>
            {comments.map((comment) => (
              <div
                key={comment.id}
                className="w-full flex flex-col md:pb-3 p-4 rounded-lg mb-2"
              >
                <div className="flex flex-row items-center mb-3">
                  <div className="rounded-full w-8 h-8 bg-customBlue text-white flex justify-center items-center text-sm font-bold">
                    {comment.username?.charAt(0).toUpperCase()}
                  </div>
                  <div className="ms-3 text-gray-800 font-semibold text-sm">
                    {comment.username.replace("_", " ")}
                  </div>
                  <div className="ms-3 text-gray-500 text-xs">
                    {getTimestamp(comment.timestamp)}
                  </div>
                  <div>
                    {user_id === comment.created_by && (
                      <button
                        className="ms-3 text-red-600 text-xs"
                        onClick={() => handleDeleteComment(comment.id)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    )}
                  </div>
                </div>
                <div className="text-sm text-gray-600 mb-2">
                  {comment.comment}
                </div>
                <div className="flex items-center space-x-4 text-sm">
                  <div
                    className={`flex items-center ${
                      comment.like.includes(user_id)
                        ? "text-green-600"
                        : "text-gray-600"
                    } cursor-pointer`}
                    onClick={() => handleCommentLike({ id: comment.id })}
                  >
                    <Like className="text-sm" height="18px" width="18px" />
                    <span className="ms-1">{comment.like.length}</span>
                  </div>
                  <div
                    className={`flex items-center ${
                      comment.dislike.includes(user_id)
                        ? "text-red-600"
                        : "text-gray-600"
                    } cursor-pointer`}
                    onClick={() => handleCommentDislike({ id: comment.id })}
                  >
                    <Dislike className="text-sm" height="18px" width="18px" />
                    <span className="ms-1">{comment.dislike.length}</span>
                  </div>
                  {/* Delete button */}
                </div>
              </div>
            ))}
          </>
        )}
      </>

      {/* Comments List */}
    </div>
  );
};

export default CommentSection;
