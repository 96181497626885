import React from "react";
import { Helmet } from "react-helmet-async";
import TitleBar from "../Common/TitleBar";
import MbTitleBar from "../Common/MbTitleBar";

const DeliveryAndShipping = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Readkar</title>
      </Helmet>
      <div className="h-full flex flex-col">
        <div className="hidden md:flex mb-10">
          <TitleBar/>
        </div>
        <div className="flex md:hidden h-20">
          <MbTitleBar />
        </div>
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg overflow-auto flex flex-grow flex-col ">
      <div className="bg-white shadow-lg rounded-lg max-w-4xl w-full p-8">
        <h1 className="text-3xl font-bold text-gray-800 text-center mb-6">
          Delivery and Shipping Policy
        </h1>
        <p className="text-gray-600 text-center mb-10">
          At Readkar, we ensure a smooth and timely delivery of your books.
          Please read our delivery and shipping policy carefully to understand
          the process.
        </p>

        <div className="space-y-8">
          {/* Section 1 */}
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              Delivery Coverage
            </h2>
            <p className="text-gray-600">
              - Our book delivery service is available exclusively in Pune,
              India.  
              - Currently, we do not provide an option for self-pickup. All books
              are delivered directly to your doorstep.
            </p>
          </div>

          {/* Section 2 */}
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              Delivery Timeline
            </h2>
            <p className="text-gray-600">
              - <strong>For books available in stock:</strong> Delivery will be
              completed within 24 hours.  
              - <strong>For books not in stock:</strong> Delivery may take up to
              48 hours.  
              - Please note, delivery timelines may occasionally be delayed due
              to unforeseen circumstances, and we appreciate your patience.
            </p>
          </div>

          {/* Section 3 */}
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              Pickup Policy
            </h2>
            <p className="text-gray-600">
              - When you're ready to return a book, simply mark it for pickup in
              your account.  
              - Our team will collect the book from your doorstep.  
              - All delivery and pickup charges are included in your subscription fees.
            </p>
          </div>

          {/* Section 4 */}
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              Contact Us
            </h2>
            <p className="text-gray-600">
              If you have any questions or concerns regarding delivery or
              shipping, feel free to contact us:
            </p>
            <ul className="mt-4 space-y-2 text-gray-600">
              <li>📞 <strong>Phone:</strong> +91-8956367644 (9:00 AM - 9:00 PM)</li>
              <li>📧 <strong>Email:</strong> support@readkar.com</li>
              <li>🏢 <strong>Address:</strong> 1404, Tower A, You 57 Society, Phase 3, Hinjewadi, Pune 411057</li>
            </ul>
          </div>
        </div>

        <p className="text-center text-gray-500 mt-10">
          ANIVEDAM TECHNOLOGIES PVT LTD
        </p>
      </div>
    </div>
    </div>
    </>
  );
};

export default DeliveryAndShipping;
