import { useState, useEffect } from "react";
import { bookFacts } from "./Facts";
import image_hero2 from "../../Assets/Image Hero-1.png";

const DidYouKnow = () => {
  const [fact, setFact] = useState("");
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const randomFact = bookFacts[Math.floor(Math.random() * bookFacts.length)];
    setFact(randomFact);
    setFade(true);
    const timeoutId = setTimeout(() => setFade(false), 5000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="flex flex-row bg-[#FFA218] h-[150px] items-center rounded-xl overflow-hidden">
      <div className="md:h-[231px] md:w-[230px] flex ]">
        <img
          src={image_hero2}
          alt="hero"
          className="h-auto w-auto object-cover"
        />
      </div>
      <div className="text-md md:text-2xl ps-4 font-sans font-semibold text-black md:mx-[86px] mx-1 text-start flex-grow">
        {fact}
      </div>
    </div>
  );
};

export default DidYouKnow;
