import { LoginResponse } from "../Serializers/SerializerModels";
import { fetchGetData } from "../Utilities/FetchUtils";
import { jwtDecode } from "jwt-decode";

export const handleToken = async (data) => {
  // Save the tokens to local storage
  localStorage.setItem("access_token", data.access_token);
  localStorage.setItem("refresh_token", data.refresh_token);

  // Decode the JWT access token
  try {
    const base64Url = data.access_token.split(".")[1]; // Extract the payload
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Base64 format adjustments
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
        .join("")
    );

    const decodedToken = JSON.parse(jsonPayload);

    // Assuming user_id is part of the payload
    if (decodedToken.user_id) {
      localStorage.setItem("user_id", decodedToken.user_id);
    } else {
      console.warn("user_id not found in the token payload.");
    }
  } catch (error) {
    console.error("Error decoding access token:", error);
  }
};

export const get_user_id = () => {
  return localStorage.getItem("user_id");
};

export const getRole = () => {
  try {
    const access_token = getAccessToken();
    const decodedToken = jwtDecode(access_token);
    const userRole = decodedToken.role;
    return userRole;
  } catch (error) {
    return "BASIC_USER";
  }
};
export const handleLogout = () => {
  localStorage.clear();
};
export const getAccessToken = () => {
  return localStorage.getItem("access_token");
};
const getRefreshToken = () => {
  return localStorage.getItem("refresh_token");
};

export const refreshAccessToken = async () => {
  const refreshToken = getRefreshToken();
  if (refreshToken) {
    try {
      const response = await fetch("http://192.168.1.2:8000/user/get_access", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${refreshToken}`,
        },
      });
      if (!response.ok) {
        return null;
      }
      const data = await response.json();
      handleToken(new LoginResponse(data.results));
      return true;
    } catch (error) {
      handleLogout();
      window.location.replace("/login");
      return null;
    }
  }
};

export const verifyLogin = async () => {
  if (getAccessToken() && getRefreshToken()) {
    const { response, error } = await fetchGetData("user/get_current_user");
    if (response) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
